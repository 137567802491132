import React from 'react'
import Detail from '../components/Detail';

const Contact = () => {
  return (
   
   
   <Detail />
   
  )
}

export default Contact