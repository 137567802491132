import React, { useState } from 'react'
import {  
  styled, 
  useTheme,
  Drawer,
  Divider,
  List,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from '@mui/material'
import { Link } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import ConstructionIcon from '@mui/icons-material/Construction';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  backgroundColor: 'rgba(225, 225, 225, 0.6)', 
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 5),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));


const itemList = [
  {
    text: "Home",
    icon: <HomeIcon />,
    to: "/"
  },
  {
    text: "Services",
    icon: <ConstructionIcon />,
    to: "/Services"
  },
  {
    text: "Contact",
    icon: <EmailIcon />,
    to: "/contact"
  }
];


const DrawerItem = () => {

  
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    < >

        <IconButton
          color="blue"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerOpen}
          sx={{ ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
        backgroundColor= 'rgba(225, 225, 225, 0.6)'
          role="presentation"
          onClick={handleDrawerClose}
          sx={{
            
            backgroundColor: 'rgba(225, 225, 225, 0.6)',
            flexGrow: 1,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >

          <Divider />
          <List
            sx={{ backgroundColor: 'rgba(225, 225, 225, 0.6)', }}
          >
            {itemList.map((item) => {
              const { text } = item;
              return (
                <ListItem

                  key={text}
                  component={Link}
                  to={item.to}
                  sx={{

                    color: 'black',
                    "&:hover": {
                      backgroundColor: '#fff',
                      color: '#1c2859',
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: 'back',
                      "&:hover": {
                        backgroundColor: 'transparent',
                        color: 'white',
                      }
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              )
            })}
          </List>
        </Drawer>
 
      
    </>
  )
}

export default DrawerItem;