import React from 'react'
import {
    Box,
    Button,
    Stack,
    ButtonGroup,
    
} from '@mui/material'
import Paragrap from './Paragrap'
import BGImg from '../assets/wall.jpg'
const Details = () => {

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
   
    }


    return (
        <Stack
            component='section'
            direction="column"
            justifyContent='center'
            alignItems='center'
            
            sx={{
                backgroundImage: `url(${BGImg})`,
                py: 10,
                px: 2,
            }}
        >
       
            <Paragrap
                
                text={
                    'Here are our service entries. To know the price of details, please contact the contact methods below'}
                maxWidth={'sm'}
                mx={0}

                textAlign={'center'}
            />

            <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{
                    mt: 1,
                    py: 2
                }}>
            </Box>
            <ButtonGroup
                disableElevation
                variant="contained"
                aria-label="Disabled button group"
            >
                <Button
                    variant='contained'
                    sx={{
                        fontSize: '0.9rem',
                        textTransform: 'capitalize',
                        py: 2,
                        px: 4,
                        mt: 3,
                        mb: 2,
                        borderRadius: 3,
                        backgroundColor: '#14192d',
                        "&:hover": {
                            backgroundColor: 'gray',

                        }
                    }}
                >
                    <a className='link-phone' href="tel:+17018055679" >Call now</a>

                </Button>
                <Button 
                    
                    variant="contained"
                    type="submit"
                    size="medium"
                    sx={{
                        fontSize: '0.9rem',
                        textTransform: 'capitalize',
                        py: 2,
                        px: 4,
                        mt: 3,
                        mb: 2,
                        borderRadius: 3,
                        backgroundColor: '#14192d',
                        "&:hover": {
                            backgroundColor: 'gray',
                            
                        }
                    }}
                >
                    <a className='link-phone' href="https://www.facebook.com/NDConstructionAndConcrete" >Facebook</a>
                </Button>
            </ButtonGroup>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfA0SVdSRHbz99sxnPCUrgtRZSK_OFwhMOuUVKpqnf5rkMAYg/viewform?embedded=true" width="100%" height="1100" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>        </Stack>
        
    )
}

export default Details;