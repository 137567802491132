import React, { Component, useState, useEffect } from 'react';
import '../Style.css';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import fjGallery from 'flickr-justified-gallery';

import "react-responsive-carousel/lib/styles/carousel.min.css";

const importAll = (r) => r.keys().map(r);

const images = importAll(require.context('../assets/img-data/bathroom', false, /\.(png|jpe?g|jpg)$/));

const PhotosBathRoom = () => {
    useEffect(() => {
        fjGallery(document.querySelectorAll(".gallery"), {
            itemSelector: ".gallery__item",
            rowHeight: 180,
            lastRow: "start",
            gutter: 2,
            rowHeightTolerance: 0.1,
            calculateItemsHeight: false
        })
    }, [])

    return (
        


            
            <LightGallery
                plugins={[lgZoom, lgVideo]}
                mode="lg-fade"
                pager={false}
                thumbnail={true}
                galleryId={"nature"}
                autoplayFirstVideo={false}
                elementClassNames={"gallery"}
                mobileSettings={{
                    controls: false,
                    showCloseIcon: false,
                    download: false,
                    rotate: false
                }}
            >
                {images.map((img, index) => (
                    <a
                        key={index}
                        data-pinterest-text="Pin it3"
                        data-tweet-text="lightGallery slide 4"
                        className="gallery__item"
                        data-src={img}
                    >
                        <img
                            className="img-responsive"
                            src={img}
                        />
                    </a>
                ))}

            </LightGallery>
            
       
    )
}



export default PhotosBathRoom;
