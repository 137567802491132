 import React from 'react'
 import {  
    Grid, 
    Typography,
    Box,
} from "@mui/material";

import Title from  './Title'
import Paragrap from  './Paragrap'


const Contents =  () => {
  return  (    
        <Box>
          
          <Grid container spacing={0}

              sx={{

                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  py: 10,
                  px: 2,
              }}
          >
              <Grid item xs={12} sm={12} md={5}
                  component='section'
              >
                  <Title
                      textColor={'white'}
                      text={
                          'What we are offering?'
                      }
                      textAlign={'start'}
                  />

                  <Typography
                      variant='h6'
                      component='h4'
                      color='black'
                      sx={{
                          fontWeight: '400',
                          paddingTop: 1,
                      }}
                  >
                      Field of construction
                  </Typography>

                  <Paragrap
                      text={
                          "ND Construction and Concrete was established in 2005 by enthusiastic young architects. Since then, we have successfully completed diverse projects and earned the trust of our clients."
                      }
                      maxWidth={'75%'}
                      mx={0}
                      textAlign={'start'}
                  />
              </Grid>
              {/* 
            <Grid item xs={12} sm={6} md={3}>
                <Card 
                square={ true }
                sx={{
                    minHeight: 200,
                    display: 'flex',
                    flexDirection:'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    border: '1px solid #ccc',
                }}>
                    <CardContent>
                        <IconButton>
                          <BathtubIcon 
                            fontSize="large"
                            color="secondary" />
                        </IconButton>
                        <Typography 
                        variant="h5" 
                        component="p"
                        sx={{
                            fontWeight: 700,
                            textTransform: 'capitalize',
                        }}
                        >
                        Bathroom
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <Card 
                square={ true }
                sx={{ 
                    minHeight: 200,
                    display: 'flex',
                    flexDirection:'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center', 
                    border: '1px solid #ccc'
                }}>
                    <CardContent>
                        <IconButton>
                          <YardIcon 
                            fontSize="large"
                            color="secondary" />
                        </IconButton>
                        <Typography 
                        variant="h5" 
                        component="p"
                        sx={{
                            fontWeight: 700,
                            textTransform: 'capitalize',
                        }}
                        >
                        BackYard
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={2}
            sx={{
                display: {xs: 'none', sm: 'block'},
            }}  
            >
                <Card 
                square={ true }
                sx={{ 
                    boxShadow: 'none',
                    minHeight: 180,
                    display: 'flex',
                    flexDirection:'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                }}>
                    <CardContent>
                        <ArrowCircleRightRoundedIcon
                        fontSize="large"
                        color="secondary" />
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>    
                <Card 
                square={ true }
                sx={{ 
                    minHeight: 200,
                    display: 'flex',
                    flexDirection:'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center', 
                    border: '1px solid #ccc'
                }}>
                    <CardContent>
                        <IconButton>
                          <OutdoorGrillIcon
                            fontSize="large"
                            color="secondary" />
                        </IconButton>
                        <Typography 
                        variant="h5" 
                        component="p"
                        sx={{
                            fontWeight: 700,
                            textTransform: 'capitalize',
                        }}
                        >
                          Outdoor Grill
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
          <Grid item xs={12} sm={6} md={3}>
              <Card
                  square={true}
                  sx={{
                      minHeight: 200,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      border: '1px solid #ccc',
                  }}
              >
                  <CardContent>
                      <IconButton>
                          <RoomPreferencesIcon
                              fontSize="large"
                              color="secondary" />
                      </IconButton>
                      <Typography
                          variant="h5"
                          component="p"
                          sx={{
                              fontWeight: 700,
                              textTransform: 'capitalize',
                          }}
                      >
                          Interior
                      </Typography>
                  </CardContent>
              </Card>
          </Grid> */}

          </Grid>
        </Box>
        
        
    );
}

export default Contents