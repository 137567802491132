import { Typography } from '@mui/material'
import React from 'react'

const Paragrap = ({ text, maxWidth, mx, textAlign }) => {
  return (
    <Typography
      sx={{
        maxWidth: maxWidth,
        mx: mx,
        textAlign: textAlign,
        py: 3,
        color: 'smoskwhite',


        backgroundColor: 'rgba(225, 225, 225, 0.7)',

      }}
    >
      {text}
    </Typography>
  )
}

export default Paragrap