import React from 'react'
import Contents from '../components/Contents';
import Tables from '../components/Tables';
import TabsGallery from '../components/TabsGallery';
import { Box } from "@mui/material";
import BGImg from '../assets/wall.jpg'

const About = () => {
  return (
    <>
    <Box 
      sx={{ backgroundImage: `url(${BGImg})`,
          py: 10,
          px: 2,
          height:'100%',
        
          
          
      }}>
        <Box
          sx={{
            backgroundColor: 'rgba(225, 225, 225, 0.7)',

          }}
        >

    <Contents />
    <Tables/>
        </Box>
    </Box>
    <TabsGallery/>
    </>
  )
}

export default About