import React from 'react'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ForwardIcon from '@mui/icons-material/Forward';
import { Box, Grid } from '@mui/material';
import Paragrap from './Paragrap';
import {
    Typography,
    Stack,
} from "@mui/material";
import Title from './Title';


function Tables() {
    const dataService = ['Garage Floors', 'Driveways', 'Exposed Aggregate', 'Floating Slabs', 'Patios', 'Pools', 'Basement Flools', 'Walls and Foootings', 'Drain Tile'];
    const dataService2 = ['Kitchen', 'Bath', 'Painting', 'Flooring', 'Concrete Driveways & more',]
    return (
        <div className='dataService'>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6}>
                        <List>

                            {dataService.map((item, index) => (
                                <ListItem key={index}>
                                    <ForwardIcon />
                                    <ListItemText primary={item} />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <List>
                            {dataService2.map((item, index) => (
                                <ListItem key={index}>
                                    <ForwardIcon />

                                    <ListItemText primary={item} />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </Box>

            <Stack
                direction='column'
                justifyContent='center'
                alignItems='center'
                sx={{
                    py: 8,
                    px: 2,
                    display: { xs: 'flex' },
                }}
            >
                <Box
                    component='section'
                    sx={{
                        paddingBottom: 3,
                    }}
                >
                    <Title
                        text={
                            'Completed projects'
                        }
                        textAlign={'center'}
                    />
                    <Typography
                        variant='h5'
                        component='h4'
                        align='center'
                        sx={{
                            paddingTop: 1,
                        }}
                    >
                        Gallery
                    </Typography>
                    <Paragrap text={
                        'Our construction company has successfully completed a diverse range of projects. We take pride in our finished works, which include both information technology and infrastructure projects. The dedication and professionalism of our team of engineers and workers have significantly contributed to the successful completion of these endeavors . We are committed to delivering value and satisfaction to our clients and look forward to collaborating on future projects. If you are seeking a reliable partner for construction projects, feel free to get in touch with us for more details.'
                    }
                        maxWidth={'sm'}
                        mx={'auto'}
                        textAlign={'center'}
                    />
                </Box>

                <Box sx={{
                    maxWidth: 700,
                    width: '100%',
                }}>
                </Box>
            </Stack>



        </div>
    )
}

export default Tables